@import 'normalize.css';
@import 'variables.scss';
@import 'mixins.scss';
@import './components/drag-n-drop.scss';


html {
    font-size: 62.5%;
    font-family: Muli, Roboto, Helvetica Neue, Arial, sans-serif;
    background-color: $blue-background;
    outline: none !important;
}

body {
    font-size: 14px;
    line-height: 1.4;
}

html, body, #root {
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: relative;
}

h1, .h1 {
    font-size: 24px;
}

h2, .h2 {
    font-size: 20px;
}

h3, .h3 {
    font-size: 16px;
}

h4, .h4 {
    font-size: 15px;
}

h5, .h5 {
    font-size: 13px;
}

h6, .h6 {
    font-size: 12px;
}

.ps > .ps__rail-y,
.ps > .ps__rail-x {
    z-index: 99;
}

a[role=button] {
    text-decoration: none;
}

/* Medium Devices, Desktops Only */
@media only screen and (min-width: 992px) {
    ::-webkit-scrollbar {
        width: 12px;
        height: 12px;
        background-color: rgba(0, 0, 0, 0);
    }

    ::-webkit-scrollbar:hover {
        width: 12px;
        height: 12px;
        background-color: rgba(0, 0, 0, 0.06);
    }

    ::-webkit-scrollbar-thumb {
        border: 2px solid transparent;
        box-shadow: inset 0 0 0 20px rgba(0, 0, 0, 0.24);
        border-radius: 20px;
    }

    ::-webkit-scrollbar-thumb:active {
        box-shadow: inset 0 0 0 20px rgba(0, 0, 0, 0.37);
        border-radius: 20px;
    }
}

form label {
    z-index: 99;
}

.header-content-user{
    min-width: 300px!important;
}

.button.emoji-mart-emoji {
    width: auto !important;
}

.input-80 input {
    width: 85%;
} 

button.emoji-mart-search-icon {
    right: 0 !important;
    width: auto !important;
    padding-bottom: 15px !important;
}

.css-bg1rzq-control {
    height: 100% !important;
    background-color: transparent !important;
}

.css-1hwfws3 {
    font-family: 'Muli' !important;
}

.css-1szy77t-control {
    height: 100% !important;
    border-color: #1b435d !important;
    box-shadow: 0 0 0 1px #1b435d !important;
    background-color: transparent !important;
}

.css-kj6f9i-menu {
    font-family: 'Muli' !important;
    z-index: 2 !important;
}

@import 'print.css';

@import 'tables.css';

@import 'react-table.css';

@import 'prism.css';

@import 'fuse-helpers.css';

@import 'styles-for-status.css';

@import '../../node_modules/react-easy-crop/react-easy-crop.css';

@import '../../node_modules/emoji-mart/css/emoji-mart.css';

