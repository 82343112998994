.drag-n-drop-section{
  .close-button{
    color: $blue-background;
    position: absolute;
    right: 5px;
    top: 5px;
    display: inline-block;
    opacity: 0;
    width: auto;
    @include transition(opacity, 0.2s);
  }
  .container-drag-item{
    user-select: none;
    min-width: 200px;
    min-height: 200px;
    padding:  30px;
    margin: 0 20px 0 0;
    border-radius: 10px;
    position: relative;
    transform: scale(1.0);
    @include transition(transform, 0.2s);
    @include general-shadow(darken($grey-background, 20%));
    &:hover {
      transform: scale(1.1);
      @include transition(transform, 0.2s);
      .close-button{
        background-color: transparent;
        opacity: 1;
        @include transition(opacity, 0.2s);
      }
    }
    .img-drag-item{
      margin-top: 20px;
      margin-left: 20px;
      width:100px;
      height:100px;
    }
  }

  .droppable-area-container{
    background: darken($grey-background, 3%);
    display: flex;
    padding: 20px;
    overflow: auto;
    border-radius: 10px;
  }
}