.bg-pending {
  background-color: #00B0FF;
}
.bg-plan {
  background-color: #F6B93B;
}
.bg-rejected {
  background-color: #EB2F06;
}
.bg-anulated {
  background-color: #CCCCCC;
}
.bg-failed {
  background-color: #0C2461;
}
.bg-delivered {
  background-color: #1F9D55;
}